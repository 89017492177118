.safetyAssessment-dialog-main[data-v-69736760] {
  background-color: #fff;
  overflow-y: auto;
}
.safetyAssessment-dialog-main .el-form[data-v-69736760] {
  display: table;
  margin-top: 20px;
  padding: 0 20px;
}
.safetyAssessment-dialog-main .el-form .el-form-item[data-v-69736760] {
  width: 50%;
  float: left;
}
.safetyAssessment-dialog-main .el-form .el-form-item[data-v-69736760] .el-input.is-disabled .el-input__inner {
  color: #C0C4CC !important;
}
.set-width[data-v-69736760] {
  width: 100% !important;
}
[data-v-69736760] .disUoloadSty .el-upload--text {
  display: none;
}